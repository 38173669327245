import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { monthLabel } from '../../static/render-calendar.js';
import TIMEFRAMES from '../timeframes';
import CATEGORIES from '../categories';
import LOCATIONS from '../locations';
import TRANSPORT from '../transport';
import Filters from './filters';
import { Deal } from '../components/deal';
import { AdUnit } from '../components/adunit';
import { NoResults } from '../components/no-results';
// import { MobileResults } from '../components/mobile-results';
import { getRandomHeroImageByCategory } from '../utils';
import buildDealListPagePath from '../build-deal-list-page-path';
import filterUnapprovedEntries from '../filter-unapproved-entries';
import { Link } from 'gatsby';
import useMobile from '../hooks/use-mobile';

const DealList = ({ data, pageContext }) => {
  const { contentfulHomePage } = data;
  const deals = filterUnapprovedEntries(data.deals.nodes);
  const { timeframe, startDate, category, subcategory, transit } = pageContext;
  const { dealsAdUnits, categories: homepageCategories } =
    contentfulHomePage || {};

  let title = 'Deals';
  let numResults = deals.length;
  const buildPath = buildDealListPagePath;
  const hasArticles = false;

  const resultsProps = {
    section: 'deals',
    num: numResults,
    timeframe: TIMEFRAMES[timeframe]?.label,
    category: CATEGORIES[category]?.label,
    subcategory: LOCATIONS[subcategory]?.label,
    transit: TRANSPORT[transit]?.label,
  };
  const isMobile = useMobile();
  const [searchString, setSearchString] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dealsPerPage = 6;

  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
  };
  const [searchOpen, setSearchOpen] = useState(false);
  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };
  useEffect(() => {
    filterOpen
      ? document.body.classList.add('hideScroll')
      : document.body.classList.remove('hideScroll');

    document.querySelector('header').classList.toggle('fixed', isMobile);
  }, [filterOpen, isMobile]);

  if (TIMEFRAMES[timeframe]) {
    title = `${TIMEFRAMES[timeframe].label}’s deals`;
  } else if (startDate) {
    title = `Deals for ${monthLabel(startDate)}`;
  }

  // Calculate pagination
  const indexOfLastDeal = currentPage * dealsPerPage;
  const indexOfFirstDeal = indexOfLastDeal - dealsPerPage;
  const [filteredDeals, setFilteredDeals] = useState(deals);
  const currentDeals = filteredDeals.slice(indexOfFirstDeal, indexOfLastDeal);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(deals.length / dealsPerPage)
  );

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };
  const handleSearchInput = async event => {
    const searchStrings = event.target.value.toLowerCase();
    setSearchString(searchStrings);
    setFilteredDeals(
      deals.filter(deal =>
        deal.title.toLowerCase().includes(searchString.toLowerCase())
      )
    );
  };
  useEffect(() => {
    setTotalPages(filteredDeals.length / dealsPerPage);
  }, [searchString, filteredDeals]);

  const catCount = {
    'Arts + Culture': 0,
    'Eat + Drink +Shop': 0,
    Outdoors: 0,
  };
  const locCount = {
    Connecticut: 0,
    'Hudson Valley': 0,
    'Long Island': 0,
    NYC: 0,
  };
  const transitCount = {
    'Metro-North': 0,
    LIRR: 0,
    Subway: 0,
  };

  const categoryCounts =
    filteredDeals.length > 0
      ? filteredDeals.reduce((_, deal) => {
          const category = deal.categories[0].title;
          catCount[category] = (catCount[category] || 0) + 1;
          return catCount;
        }, {})
      : {};

  const locationCounts =
    filteredDeals.length > 0
      ? filteredDeals.reduce((_, deal) => {
          const location = deal.subcategories[0].title;
          locCount[location] = (locCount[location] || 0) + 1;
          return locCount;
        }, {})
      : {};

  const transportCounts =
    filteredDeals.length > 0
      ? filteredDeals.reduce((_, deal) => {
          const transport = deal.transit?.routes[0].system;
          transitCount[transport] = (transitCount[transport] || 0) + 1;
          return transitCount;
        }, {})
      : {};
  const filterResults = { categoryCounts, locationCounts, transportCounts };

  return (
    <Layout title={title} mainClass="deals" currentSection="deals">
      {isMobile && (
        <div
          id="searchButton__mobile"
          aria-label="Search"
          onKeyDown={handleSearchToggle}
          onClick={handleSearchToggle}
        >
          search
        </div>
      )}
      <div className={`section-header ${filterOpen ? 'removeShadow' : ''}`}>
        <div className="section-header-inner">
          <h1 className="section-title">Deals</h1>
        </div>
        {isMobile && !filterOpen && (
          <div className="section-filtering">
            <div className="mobile-results"></div>
            <button
              className="mobile-filter-btn"
              data-heading-filters
              onClick={() => {
                handleFilterToggle();
              }}
            >
              <span>Filters</span>
            </button>
          </div>
        )}
      </div>
      <div className="article-page">
        {isMobile && (
          <>
            <div
              className={`search mobile ${searchOpen ? '' : 'hide'}`}
              style={{ top: '-76px' }}
            >
              <div className="filter-header-inner__mobile">
                <h1>
                  <Link to="/">
                    <img
                      src="/logo.png"
                      alt="MTA Away"
                      id="mta-logo__mobile-search"
                    />
                  </Link>
                </h1>
                <div
                  id="closeButton__mobile"
                  aria-label="Close"
                  onKeyDown={handleSearchToggle}
                  onClick={handleSearchToggle}
                  data-heading-filters
                >
                  close
                </div>
                <div className="search__mobile-wrapper">
                  <p className="event-filters__label">Search</p>
                  <span className="material-icons icon-search__filter">
                    search
                  </span>
                  <input
                    className="event-filter__search"
                    placeholder="i.e: Montauk"
                    type="text"
                    onChange={handleSearchInput}
                  />
                </div>
              </div>
            </div>
            <div className={`filters mobile ${filterOpen ? '' : 'hide'}`}>
              <Filters
                {...{
                  filterResults,
                  category,
                  subcategory,
                  timeframe,
                  numResults,
                  buildPath,
                  hasArticles,
                  isMobile,
                  handleFilterToggle,
                }}
              />
            </div>
          </>
        )}

        <ul
          className={`article article--deals ${
            dealsAdUnits?.length ? '' : 'no-border'
          }`}
        >
          {!currentDeals.length && (
            <NoResults
              message={`There are no deals for ${
                TIMEFRAMES[timeframe]?.label || 'All dates'
              }", "${CATEGORIES[category]?.label || 'All categories'}", "${
                TRANSPORT[transit]?.label
              } "and "${LOCATIONS[subcategory]?.label || 'All locations'}."`}
            />
          )}
          {currentDeals.map(deal => {
            const newDeal = { ...deal };
            if (!deal.heroImage) {
              newDeal.heroImage = getRandomHeroImageByCategory(
                deal.categories,
                homepageCategories
              );
            }

            return <Deal key={deal.id} deal={newDeal} />;
          })}
          {filteredDeals.length > dealsPerPage && (
            <div className="pagination">
              <div> Continue exploring our deals </div>
              <div className="pagination__page-numbers">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    className={`pagination-item ${
                      currentPage === index + 1 || currentPage === 0
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          )}
        </ul>
        <div className="sidebar">
          <div className="sidebar-inner">
            {!isMobile && (
              <>
                <p className="event-filters__label">Search</p>
                <span className="material-icons icon-search__filter">
                  search
                </span>
                <input
                  className="event-filter__search"
                  placeholder="i.e: Tour"
                  type="text"
                  onChange={handleSearchInput}
                />
                <Filters
                  {...{
                    category,
                    subcategory,
                    timeframe,
                    transit,
                    filterResults,
                    numResults,
                    buildPath,
                    hasArticles,
                    isMobile,
                    handleFilterToggle,
                  }}
                />
              </>
            )}
            {dealsAdUnits &&
              dealsAdUnits.map(ad => <AdUnit key={ad.id} ad={ad} />)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

// startDate and endDate will always be set
// and can either be expected values (the range derived from `timeframe`)
// or can be today's ISO for startDate and the oldest deal endDate
// we are essentially filtering with our query instead of doing it client side
export const query = graphql`
  query DealsQuery(
    $category: String
    $subcategory: String
    $startDate: Date!
    $endDate: Date!
    $transit: String
  ) {
    deals: allContentfulDeal(
      filter: {
        categories: { elemMatch: { slug: { eq: $category } } }
        subcategories: { elemMatch: { slug: { eq: $subcategory } } }
        startDate: { lte: $endDate }
        endDate: { gte: $startDate }
        transit: { routes: { elemMatch: { system: { eq: $transit } } } }
      }
      sort: { fields: startDate, order: ASC }
    ) {
      nodes {
        ...DealListFragment
      }
    }

    contentfulHomePage {
      ...DealsAdsFragment
      categories {
        slug
        featuredImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
        }
      }
    }
  }
`;

export default DealList;
