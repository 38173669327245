// Generates URLs for Deals pages.
// Timeframe is a keyword like `this-weekend`
// If category or subcategory are omitted, the path part is omitted as well.
// All of these are examples of valid outputs of this function:
// /deals/arts-culture/long-island/this-weekend
// /deals/long-island/this-weekend
// /deals/outdoors
// /deals/nyc
// /deals/next-month
// /deals
// module.exports = (category, subcategory, timeframe) => {
//   return (
//     '/' + ['deals', category, subcategory, timeframe].filter(Boolean).join('/')
//   );
// };
module.exports = (category, subcategory, timeframe, transit) => {
  return (
    '/' +
    ['deals', category, subcategory, timeframe, transit]
      .filter(Boolean)
      .join('/')
  );
};
